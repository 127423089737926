<template>
    <div>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <v-snackbar
              top
              v-model="snackbar"
          >
            {{ msg }}
            <template v-slot:action="{ attrs }">
              <v-btn
                  height="60px"
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <v-form v-model="valid">
            <v-card class="pa-4" style="border-radius: 20px;" color="#f4f5f8" elevation="10">
              <v-card-text>
                <v-row>
                  <v-col sm="4">
                    <v-text-field :rules="emailRules"
                                  label="Email"
                                  v-model="email"></v-text-field>
                  </v-col>
                  <v-col sm="4">
                    <v-text-field :rules="newPasswordRules"
                                  label="New Password"
                                  v-model="newPassword"></v-text-field>
                  </v-col>
                  <v-col sm="4">
                    <v-text-field v-model="newPasswordConfirmation"
                                  :rules="newPasswordConfirmationRules"
                                  label="Password Confirmation"></v-text-field>
                  </v-col>
                  <v-row>
                    <v-col md="5" sm="5">

                    </v-col>
                    <v-col md="2" sm="2">
                      <v-row>
                        <v-btn
                            height="60px"
                            v-if="this.valid" color="#4BB543" @click="changePass">Update</v-btn>
                        <v-btn
                            height="60px"
                            v-else disabled>Update</v-btn>
                      </v-row>
                    </v-col>
                    <v-col md="5" sm="5">

                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </div>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      snackbar:false,
      msg:'',
      email: null,
      newPassword: null,
      newPasswordConfirmation: null,
      valid: false,
      emailRules: [
        v => !!v || 'email is required',
      ],
      newPasswordRules: [
        v => (!!v && v.length >= 6) || 'new password is required to be a at least 6 characters',
      ],
      newPasswordConfirmationRules: [
        v => (!!v && v == this.newPassword) || 'Password confirmation failed'
      ]
    }
  },
  methods: {
    changePass() {
      window.axios.post('api/auth/reset-password', {
        token: this.$route.query.token,
        email: this.email,
        password: this.newPassword,
        password_confirmation: this.newPasswordConfirmation
      }).then(() => {
        this.snackbar = true
        this.msg = "Password Changed! You can use your new password to login to your account!"
      }).catch((err) => {
        console.log(err)
        this.snackbar = true
        if (err.response.data.errors.email) {
          this.msg = err.response.data.errors.email[0]
        } else {
          this.msg = err.response.data.errors.password[0]
        }
      })
    }
  }
}
</script>

<style scoped>

</style>